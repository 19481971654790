/**
 * Amasty Special Promotions Pro compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { connect } from 'react-redux';

import Banners from './Banners.component';
/** @namespace Scandiweb/AmastySpecialPromotionsPro/Component/Banners/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    banners: state.ProductReducer?.product?.banners,
});

/** @namespace Scandiweb/AmastySpecialPromotionsPro/Component/Banners/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Banners);
