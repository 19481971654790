import { connect } from 'react-redux';

import {
    mapDispatchToProps,
} from 'SourceComponent/Product/Product.container';
import {
    mapStateToProps as sourceMapStateToProps,
    ProductActionsContainer as SourceProductActionsContainer,
} from 'SourceComponent/ProductActions/ProductActions.container';
import {
    ProductActionsContainerProps,
    ProductActionsContainerState,
} from 'SourceComponent/ProductActions/ProductActions.type';
import { ReactElement } from 'SourceType/Common.type';
import { RootState } from 'Util/Store/Store.type';

import ProductActions from './ProductActions.component';
/** @namespace Inov8/Component/ProductActions/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Inov8/Component/ProductActions/Container */
export class ProductActionsContainer extends SourceProductActionsContainer {
    componentDidUpdate(prevProps: ProductActionsContainerProps, prevState: ProductActionsContainerState): void {
        super.componentDidUpdate(prevProps, prevState);

        const { selectedProduct: prevSelectedProduct, parameters: prevParameters } = prevState;
        const { selectedProduct, parameters } = this.state;
        const { setActiveProduct, setParameters } = this.props;

        // Updates ProductPages active product state, to
        // match selected product variant
        if (selectedProduct !== prevSelectedProduct) {
            setActiveProduct(selectedProduct || {});
        }

        if (parameters !== prevParameters && setParameters) {
            setParameters(parameters || {});
        }
    }

    render(): ReactElement {
        const {
            setParameters,
            isPdp,
        } = this.props;

        return (
            <ProductActions
              { ...this.containerProps() }
              { ...this.containerFunctions }
              setParameters={ setParameters }
              isPdp={ isPdp }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductActionsContainer);
