/* eslint-disable @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import 'Component/Dragger/Dragger.style';

/** @namespace Inov8/Component/Dragger/Component */
export class DraggerComponent extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,

    };

    __construct(props) {
        super.__construct(props);
        this.scrollContainer = React.createRef();
        this.state = {
            isDragging: false,
            startX: 0,
            scrollLeft: 0,
        };
    }

    componentDidMount() {
        const { current: container } = this.scrollContainer;
        container.addEventListener('mousedown', this.startDrag);
        window.addEventListener('mouseup', this.stopDrag);
        window.addEventListener('mousemove', this.handleDrag);
    }

    componentWillUnmount() {
        const { current: container } = this.scrollContainer;
        container.removeEventListener('mousedown', this.startDrag);
        window.removeEventListener('mouseup', this.stopDrag);
        window.removeEventListener('mousemove', this.handleDrag);
    }

    startDrag = (e) => {
        const { current: container } = this.scrollContainer;
        this.setState({
            isDragging: true,
            startX: e.pageX - container.offsetLeft,
            scrollLeft: container.scrollLeft,
        });
    };

    stopDrag = () => {
        this.setState({ isDragging: false });
    };

    handleDrag = (e) => {
        const { isDragging, startX, scrollLeft } = this.state;

        if (!isDragging) {
            return;
        }
        e.preventDefault();
        const x = e.pageX - this.scrollContainer.current.offsetLeft;
        const walk = (x - startX); // scroll-fastness
        this.scrollContainer.current.scrollLeft = scrollLeft - walk;
    };

    render() {
        const { isDragging } = this.state;
        const { children } = this.props;

        // Dynamic styles for cursor change when dragging
        const dynamicDraggerStyle = {
            cursor: isDragging ? 'grabbing' : 'grab',
        };

        // Styles to hide the scrollbar
        const wrapperStyle = {
            overflow: 'hidden',
        };

        const contentStyle = {
            overflowX: 'auto',
            paddingBottom: '20px', // Adjust if necessary
            marginBottom: '-20px', // Adjust if necessary
            // Hide scrollbar for WebKit browsers
            WebkitScrollbar: {
                display: 'none',
            },
        };

        return (
            <div style={ wrapperStyle }>
                <div
                  block="Dragger"
                  elem={ isDragging ? 'Dragging' : 'Draggable' }
                  ref={ this.scrollContainer }
                  style={ { ...dynamicDraggerStyle, ...contentStyle } }
                >
                    { children }
                </div>
            </div>
        );
    }
}

export default DraggerComponent;
