/**
 * Klarna Checkout compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { noopFn } from '@scandipwa/scandipwa/src/util/Common';
import {
    useEffect, useRef, useState,
} from 'react';

import getStore from 'Util/Store';

import { HUNDRED, ON_SITE_MESSAGING_SDK_ID, ON_SITE_MESSAGING_SDK_URL } from '../../config';
import { loadScript } from '../../util/klarna';

import './KlarnaOnSiteMessaging.style';

/** @namespace Scandiweb/KlarnaCheckoutSpwa/Component/KlarnaOnSiteMessaging/KlaranaOnSiteMessaging/Component/RenderKlarnaOnSiteMessaging */
export const RenderKlarnaOnSiteMessaging = (instanceProps) => {
    const config = getStore().getState()?.ConfigReducer?.klarnaConfig?.klarnaOnSiteMessagingConfig;

    const timer = useRef();
    const [scriptState, setScriptState] = useState(false);

    const {
        instanceProps: {
            isCart,
            osmPrice = 0,
        } = {},
    } = instanceProps;

    const {
        dataId,
        productPlacementSelect,
        cartPlacementSelect,
        testMode,
    } = config || {};

    useEffect(() => {
        if (!dataId) {
            return noopFn;
        }

        timer.current = setInterval(() => {
            loadScript(ON_SITE_MESSAGING_SDK_ID, ON_SITE_MESSAGING_SDK_URL, dataId, testMode).then(setScriptState);
        }, 50);

        return () => clearInterval(timer.current);
    }, []);

    useEffect(() => {
        if (scriptState) {
            clearInterval(timer.current);
        }
    }, [scriptState]);

    window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
    window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' });

    if (!scriptState) {
        return null;
    }

    return (
      <div
        block="Klarna"
        elem="OnSiteMessaging"
      >
            <klarna-placement
              data-key={ isCart ? cartPlacementSelect : productPlacementSelect }
              // Using the local variable returned from the backend (e.g., en-US) results in an error.
              // Uncertain about the acceptable values for Klarna.
              data-locale="en-GB"
              data-purchase-amount={ osmPrice * HUNDRED }
            />
      </div>
    );
};
