/**
 * Amasty Special Promotions Pro compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { TOP_BANNER } from '../../config/Banner.config';
import {
    altText,
    hoverText,
    image,
    link,
    position,
    renderEmpty,
} from '../../types/Banner.type';
import Banner from './Banner.component';

/** @namespace Scandiweb/AmastySpecialPromotionsPro/Component/Banner/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    banners: state.ProductReducer.product.banners,
});

/** @namespace Scandiweb/AmastySpecialPromotionsPro/Component/Banner/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandiweb/AmastySpecialPromotionsPro/Component/Banner/Container */
export class BannerContainer extends PureComponent {
    static propTypes = {
        image: image.isRequired,
        link,
        altText,
        hoverText,
        position,
        renderEmpty,
    };

    static defaultProps = {
        link: '',
        altText: '',
        hoverText: '',
        position: TOP_BANNER,
        renderEmpty: false,
    };

    containerProps() {
        const {
            image,
            link,
            altText,
            hoverText,
            position,
            renderEmpty,
        } = this.props;

        return {
            image,
            link,
            altText,
            hoverText,
            position,
            renderEmpty,
        };
    }

    render() {
        return (
            <Banner
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerContainer);
