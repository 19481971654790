import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductPriceContainer as SourceProductPriceContainer,
} from 'SourceComponent/ProductPrice/ProductPrice.container';
import { ReactElement } from 'SourceType/Common.type';

import ProductPrice from './ProductPrice.component';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace Inov8/Component/ProductPrice/Container */
export class ProductPriceContainer extends SourceProductPriceContainer {
    render(): ReactElement {
        return (
            <ProductPrice
              { ...this.containerProps() }
              parameters={ this.props?.parameters }
              product={ this.props?.product }
              isPdp={ this.props?.isPdp }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
