/**
 * Klarna Checkout compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Suspense } from 'react';

import getStore from 'Util/Store';

import { RenderKlarnaOnSiteMessaging } from '../component/KlarnaOnSiteMessaging/KlaranaOnSiteMessaging.component';
import { getProductPrice } from '../util/getPrice';

const renderAddToCartActionBlock = (args, callback, instance) => {
    const config = getStore().getState()?.ConfigReducer?.klarnaConfig?.klarnaOnSiteMessagingConfig;

    const { productEnabled } = config || {};

    if (!productEnabled) {
        return callback(...args);
    }

    return (
        <>
            { callback(...args) }
            <Suspense fallback={ null }>
                <RenderKlarnaOnSiteMessaging instanceProps={ instance.props } />
            </Suspense>
        </>
    );
};

const AddPriceAndIsCartToProps = (args, callback) => {
    const containerProps = callback(...args);
    const osmPrice = getProductPrice(containerProps);

    return {
        ...containerProps,
        osmPrice,
        isCart: false,
    };
};

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            renderAddToCartActionBlock,
        },
    },
    'Component/ProductActions/Container': {
        'member-function': {
            containerProps: AddPriceAndIsCartToProps,
        },
    },
};
