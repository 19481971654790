/**
 * Klarna Checkout compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import BrowserDatabase from 'Util/BrowserDatabase';
import { getCurrency } from 'Util/Currency';
import { appendTokenToHeaders } from 'Util/Request/Request';

import { LOCAL_STORAGE_KLARNA_ERROR } from '../config';

export const KLARNA_SUCCESS_STEP = 'pagesuccess';
export const KLARNA_ORDER_ID_PARAM = 'klarnaOrderId';
export const PLAYGROUND_MODE = 'playground';
export const PRODUCTION_MODE = 'production';

/**
 * @description Custom function based on 'Util/Request'->postFetch function
 *              to create post request with additional header 'X-Requested-With'
 * @param {String} graphQlURI
 * @param {String} queryObject
 * @param {String} name
 * @returns {Promise<Response>}
 * @namespace Scandiweb/KlarnaCheckoutSpwa/Util/Klarna/postFetchWithExtraHeader */
export const postFetchWithExtraHeader = (
    graphQlURI,
    query,
    variables,
) => fetch(
    graphQlURI,
    {
        method: 'POST',
        body: JSON.stringify({ query, variables }),
        headers: appendTokenToHeaders({
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Currency': getCurrency(),
            Accept: 'application/json',
        }),
    },
);

/**
 * @returns {Void}
 * @namespace Scandiweb/KlarnaCheckoutSpwa/Util/Klarna/resumeKlarnaIframe */
export const resumeKlarnaIframe = () => {
    if (window._klarnaCheckout) {
        window._klarnaCheckout((api) => {
            api.resume();
        });
    }
};

/**
 * @returns {Void}
 * @namespace Scandiweb/KlarnaCheckoutSpwa/Util/Klarna/suspendKlarnaIframe */
export const suspendKlarnaIframe = () => {
    if (window._klarnaCheckout) {
        window._klarnaCheckout((api) => {
            api.suspend();
        });
    }
};

/**
 * @returns {Promise}
 * @namespace Scandiweb/KlarnaCheckoutSpwa/Util/Klarna/updateKlarnaIframe */
export const updateKlarnaIframe = async (updateKlarnaOrderUrl) => {
    try {
        suspendKlarnaIframe();

        await postFetchWithExtraHeader(updateKlarnaOrderUrl).then(
            /** @namespace Scandiweb/KlarnaCheckoutSpwa/Util/Klarna/updateKlarnaIframe/postFetchWithExtraHeader/then */
            () => {
                resumeKlarnaIframe();
            }
        );
    } catch (e) {
        BrowserDatabase.setItem(e, LOCAL_STORAGE_KLARNA_ERROR);
    }
};

/**
 * @description Check for Klarna success page url
 * @returns {Boolean}
 * @namespace Scandiweb/KlarnaCheckoutSpwa/Util/Klarna/isKlarnaSuccessStep */
export const isKlarnaSuccessStep = () => window.location.href.includes(KLARNA_SUCCESS_STEP);

/**
 * @description Get kalrnaOrderId in URL params
 * @returns {String}
 * @namespace Scandiweb/KlarnaCheckoutSpwa/Util/Klarna/getOrderIdFromParams */
export const getOrderIdFromParams = () => {
    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.get(KLARNA_ORDER_ID_PARAM);
};

/** @namespace Scandiweb/KlarnaCheckoutSpwa/Util/Klarna/getFormattedFields */
export const getFormattedFields = (fields) => ({
    city: fields.city,
    company: fields.company,
    country_id: fields.countryId,
    email: fields.email,
    firstname: fields.firstname,
    lastname: fields.lastname,
    postcode: fields.postcode,
    region: fields.region,
    region_code: fields.regionCode,
    region_id: fields.regionId,
    street: fields.street,
    telephone: fields.telephone,
});

/** @namespace Scandiweb/KlarnaCheckoutSpwa/Util/Klarna/loadScript */
export const loadScript = (id, src, clientId, testMode) => {
    const loadedScript = document.getElementById(id);

    if (loadedScript) {
        return Promise.resolve(true);
    }

    const script = document.createElement('script');

    script.setAttribute('type', 'application/javascript');
    script.setAttribute('src', src);
    script.setAttribute('async', true);
    script.setAttribute('id', id);
    script.setAttribute('data-environment', testMode ? PLAYGROUND_MODE : PRODUCTION_MODE);
    script.setAttribute('data-client-id', clientId);
    const loading = new Promise((resolve) => {
        script.onload = () => {
            script.setAttribute('data-loaded', true);
            resolve(true);
        };
    });

    document.head.appendChild(script);

    return loading;
};
