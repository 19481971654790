import { Dispatch } from 'redux';

import { updateMeta } from 'SourceStore/Meta/Meta.action';
import {
    MetaDispatcher as SourceMetaDispatcher,
} from 'SourceStore/Meta/Meta.dispatcher';
import { Category, PageMeta, ProductMeta } from 'SourceStore/Meta/Meta.type';

/** @namespace Inov8/Store/Meta/Dispatcher */
export class MetaDispatcher extends SourceMetaDispatcher {
    /**
     * Set meta for category
     * @param {Object} category
     * @param {Function} dispatch
     * @memberof MetaDispatcher
     */
    updateWithCategory(category: Category, dispatch: Dispatch): void {
        const meta = this._getCategoryMeta(category);

        dispatch(updateMeta(meta));
    }

    /**
     * Set meta for product
     * @param {Object} product
     * @param {Function} dispatch
     * @memberof MetaDispatcher
     */
    updateWithProduct(product: ProductMeta, dispatch: Dispatch): void {
        const meta = this._getProductMeta(product);

        dispatch(updateMeta(meta));
    }

    /**
     * Get meta for product
     * @param {Object} product
     * @return {Object} Meta object
     * @memberof MetaDispatcher
     */
    _getProductMeta(product: ProductMeta): Partial<PageMeta> {
        const {
            name,
            meta_title,
            meta_keyword,
            meta_description,
        } = product;

        return {
            description: meta_description,
            keywords: meta_keyword,
            title: meta_title || name,
        };
    }

    /**
     * Get meta for category
     * @param {Object} category
     * @return {Object} Meta object
     * @memberof MetaDispatcher
     */
    _getCategoryMeta(category: Category): Partial<PageMeta> {
        const {
            description,
            name,
            meta_title,
            meta_keywords,
            meta_description,
            meta_robots = 'follow, index',
        } = category;

        return {
            description: meta_description || description,
            title: meta_title || name,
            keywords: meta_keywords,
            robots: meta_robots,
        };
    }
}

export default new MetaDispatcher();
