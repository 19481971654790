/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable fp/no-let */
import { Money } from '@scandipwa/scandipwa/src/query/ProductList.type';
import { GQLCurrencyEnum } from '@scandipwa/scandipwa/src/type/Graphql.type';

import {
    ProductPriceComponent as SourceProductPriceComponent,
} from 'SourceComponent/ProductPrice/ProductPrice.component';
import { DisplayProductPricesInCatalog } from 'SourceComponent/ProductPrice/ProductPrice.config';
import { FormattedMoney } from 'SourceUtil/Product/Product.type';
import { ReactElement } from 'Type/Common.type';
import { formatPrice } from 'Util/Price';

import './ProductPrice.style';

/** @namespace Inov8/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPriceComponent {
    renderOldPrice(): ReactElement {
        const {
            price: {
                originalPrice: {
                    value: originalPriceValue,
                    valueFormatted: originalPriceFormatted,
                } = {},
            } = {},
            discountPercentage,
            isSchemaRequired,
            variantsCount,
        } = this.props;

        if (discountPercentage === 0 || originalPriceValue === 0) {
            return null;
        }

        return (
            <div
              block="ProductPrice"
              elem="HighPrice"
              aria-label={ __('Old product price') }
              itemProp={ isSchemaRequired && variantsCount > 1 ? 'highPrice' : undefined }
            >
                { originalPriceFormatted }
            </div>
        );
    }

    renderPriceWithOrWithoutTax(
        basePrice: Partial<FormattedMoney>,
        taxPrice: Partial<FormattedMoney>,
        label?: string | ReactElement,
    ): ReactElement {
        const { displayTaxInPrice } = this.props;

        if (displayTaxInPrice === DisplayProductPricesInCatalog.INCL_TAX) {
            return this.renderPrice(basePrice, label);
        }

        if (displayTaxInPrice === DisplayProductPricesInCatalog.EXCL_TAX) {
            return this.renderPrice(taxPrice, __('Excl Tax'));
        }

        return (
            <>
                { this.renderSubPrice(taxPrice) }
                { this.renderPrice(basePrice, label) }
            </>
        );
    }

    renderPrice(price: Partial<FormattedMoney>, label: string | ReactElement): ReactElement {
        const {
            discountPercentage,
            originalPrice: {
                maxFinalPrice,
            },
            product,
            parameters,
            isPdp,
            priceCurrency,
        } = this.props;

        const {
            value: priceValue,
            valueFormatted: priceFormatted = 0,
        } = price;

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

        if ((!priceValue && priceValue !== 0)) {
            return null;
        }

        if (product && isPdp) {
            let foundMatch = false;
            let price: Money = {
                currency: GQLCurrencyEnum.GBP,
                value: 0,
            };

            let maxPrice: Money = {
                currency: GQLCurrencyEnum.GBP,
                value: 0,
            };

            if (parameters?.colours && product.type_id === 'configurable') {
                product.variants?.forEach((variant) => {
                    if (!foundMatch) {
                        const variantColour = variant.attributes.colours.attribute_value;
                        // console.log(`Checking if ${colours} equals ${variantColour}`);

                        if (parameters.colours === variantColour) {
                            price = variant.price_range.maximum_price.default_final_price;
                            foundMatch = true;
                            maxPrice = variant.price_range.maximum_price.regular_price;
                        }
                    }
                });

                if (price?.value && price?.currency) {
                    return (
                    <PriceSemanticElementName block="ProductPrice" elem="Price">
                        <meta itemProp="priceCurrency" content={ String(priceCurrency) } />
                        <span
                          itemScope
                          block="ProductPrice"
                          elem="PriceValue"
                        >
                            { (price.value !== maxPrice.value) ? (
                                <>
                                    { formatPrice(price.value, price.currency) }
                                    { ' ' }
                                    <span block="ProductPrice" elem="HighPrice">
                                        { formatPrice(maxPrice.value, maxPrice.currency) }
                                    </span>
                                </>
                            ) : (
                                formatPrice(price.value, price.currency)
                            ) }

                        </span>
                    </PriceSemanticElementName>
                    );
                }
            }
        }

        if (label === 'As Low as') {
            return (
                <>
                    <meta itemProp="priceCurrency" content={ String(priceCurrency) } />
                    <PriceSemanticElementName block="ProductPrice" elem="Price">
                        <span
                          itemScope
                          block="ProductPrice"
                          elem="PriceValue"
                        >
                            { priceFormatted }
                            { ' ' }
                            -
                            { ' ' }
                            { maxFinalPrice?.valueFormatted }
                        </span>
                    </PriceSemanticElementName>
                </>
            );
        }

        return (
            <>
            <meta itemProp="priceCurrency" content={ priceCurrency } />
            <PriceSemanticElementName block="ProductPrice" elem="Price">
                { this.renderPriceBadge(label) }
                <span
                  itemScope
                  block="ProductPrice"
                  elem="PriceValue"
                >
                    { priceFormatted }
                </span>
            </PriceSemanticElementName>
            </>
        );
    }

    renderConfigurablePrice(): ReactElement {
        const {
            originalPrice: {
                minFinalPrice: { value: minValue = 0 } = {},
                maxFinalPrice: { value: maxValue = 0 } = {},
            },
            configuration: {
                containsOptions = false,
            } = {},
            price: {
                finalPriceExclTax = {},
                finalPrice = {},
            },
            priceType,
            isPdp,
        } = this.props;

        if (minValue === maxValue && !containsOptions && !isPdp) {
            return this.renderDefaultPrice();
        }

        const { [priceType]: label } = this.priceLabelTypeMap;

        return this.renderPriceWithOrWithoutTax(finalPrice, finalPriceExclTax, label);
    }

    render(): ReactElement {
        const {
            price: {
                finalPrice,
                originalPrice,
                finalPrice: {
                    value: finalPriceValue = 0,
                } = {},
            } = {},
            priceType,
            isPreview,
            discountPercentage,
            mix,
        } = this.props;

        if (!finalPrice || !originalPrice) {
            return this.renderPlaceholder();
        }

        const { [priceType]: renderer } = this.pricePreviewRenderMap;

        return (
            <div
              block="ProductPrice"
              mods={ { hasDiscount: discountPercentage !== 0, isPreview } }
              mix={ mix }
              aria-label={ `Product price: ${finalPriceValue}` }
            >
                { isPreview && renderer && renderer() }
                { (!isPreview || !renderer) && this.renderDefaultPrice() }
            </div>
        );
    }
}

export default ProductPriceComponent;
