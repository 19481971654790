import {
    DisplayProductPricesInCatalog as SourceDisplayProductPricesInCatalog,
} from 'SourceComponent/ProductPrice/ProductPrice.config';

export enum DisplayProductPricesInCatalog {
    INCL_TAX = 'DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX',
    EXCL_TAX = 'DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX',
    BOTH = 'DISPLAY_PRODUCT_PRICES_IN_CATALOG_BOTH',
}

export type DisplayProductPricesInCatalogType = SourceDisplayProductPricesInCatalog & DisplayProductPricesInCatalog;
