// import Draggable from 'Source/component/Draggable/Draggable.component';
import ContentWrapper from '@scandipwa/scandipwa/src/component/ContentWrapper';
import RenderWhenVisible from '@scandipwa/scandipwa/src/component/RenderWhenVisible';

import Dragger from 'Component/Dragger/Dragger.component';
import ProductCard from 'SourceComponent/ProductCard';
import {
    ProductLinksComponent as SourceProductLinksComponent,
} from 'SourceComponent/ProductLinks/ProductLinks.component';

import './ProductLinks.style';

/** @namespace Inov8/Component/ProductLinks/Component */
export class ProductLinksComponent extends SourceProductLinksComponent {
    renderProductCard(product, i) {
        const { id = i } = product;

        return (
            <ProductCard
              block="ProductLinks"
              elem="Card"
              product={ product }
              key={ id }
              addToCartBtn={ false }
              reviewsStars={ false }
              wishlistBtn={ false }
              compareBtn={ false }
              shortDescription={ false }
              swatches
              isProductLinks
            />
        );
    }

    render() {
        const { areDetailsLoaded } = this.props;

        if (!areDetailsLoaded) {
            return null;
        }

        return (
            <RenderWhenVisible>
                <ContentWrapper
                  mix={ { block: 'ProductLinks' } }
                  wrapperMix={ { block: 'ProductLinks', elem: 'Wrapper' } }
                  label={ __('Linked products') }
                >
                    { this.renderHeading() }
                    <Dragger>
                    <ul block="ProductLinks" elem="List">
                        { this.renderItems() }
                    </ul>
                    </Dragger>
                </ContentWrapper>
            </RenderWhenVisible>
        );
    }
}

export default ProductLinksComponent;
