/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { MediaType } from '@scandipwa/scandipwa/src/component/ProductGallery/ProductGallery.config';
import Slider from '@scandipwa/scandipwa/src/component/Slider';
import { MediaGalleryEntry } from '@scandipwa/scandipwa/src/query/ProductList.type';
import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';
import { setLoadedFlag } from '@scandipwa/scandipwa/src/util/Request/LowPriorityLoad';
// @ts-ignore
import { Suspense } from 'react';
import { TransformWrapper } from 'react-zoom-pan-pinch';

import Image from 'Component/Image';
import ProductGalleryBaseImage from 'Component/ProductGalleryBaseImage';
import {
    CarouselScroll as SourceCarouselScroll,
    ProductGalleryComponent as SourceProductGalleryComponent,
    VideoPopup,
} from 'SourceComponent/ProductGallery/ProductGallery.component';

import './ProductGallery.override.style';

export {
    VideoPopup,
};

// TODO: implement CarouselScroll
export const CarouselScroll = SourceCarouselScroll;

/** @namespace Inov8/Component/ProductGallery/Component */
export class ProductGalleryComponent extends SourceProductGalleryComponent {
    __construct(props:any) {
        super.__construct(props);
        this.state = {
            ...this.state,
            activeImage: 0,
        };

        this.onActiveImageChange = this.onActiveImageChange.bind(this);
    }

    onActiveImageChange(index:any) {
        this.setState({ activeImage: index });
    }

    renderImage(mediaData: MediaGalleryEntry, index: number): ReactElement {
        const {
            isZoomEnabled,
            handleZoomChange,
            disableZoom,
            isMobile,
            isImageZoomPopupActive,
            showLoader,
            isQuickAdd,
        } = this.props;
        const { scrollEnabled } = this.state;

        if (!isMobile && !isQuickAdd) {
            const {
                base: { url: baseSrc } = {},
                large: { url: largeSrc } = {},
            } = mediaData;

            const style = isImageZoomPopupActive ? { height: 'auto' } : {};
            const src = isImageZoomPopupActive ? largeSrc || baseSrc : baseSrc;

            return (
                <Image
                  key={ index }
                  src={ src }
                  mix={ {
                      block: 'ProductGallery',
                      elem: 'SliderImage',
                      mods: { isPlaceholder: !src },
                  } }
                  isPlaceholder={ !src }
                  style={ style }
                  showIsLoading={ showLoader }
                  // eslint-disable-next-line react/jsx-no-bind
                  onImageLoad={ () => {
                      setLoadedFlag();
                      window.isPrefetchValueUsed = false;
                  } }
                />
            );
        }

        return (
            <TransformWrapper
              key={ index }
              onZoomChange={ handleZoomChange }
              onWheel={ this.onWheel }
              wheel={ { limitsOnWheel: true, disabled: !scrollEnabled } }
              pan={ {
                  disabled: !isZoomEnabled,
                  velocity: false,
              } }
              options={ {
                  limitToBounds: true,
                  minScale: 1,
              } }
            >
                { /* @ts-ignore */ }
                { (params: TransformRenderFnProps): ReactNode => {
                    const {
                        scale,
                        previousScale,
                        resetTransform,
                        setTransform,
                    } = params;

                    if (scale === 1 && previousScale !== 1) {
                        resetTransform();
                    }

                    return (
                        <ProductGalleryBaseImage
                          setTransform={ setTransform }
                          index={ index }
                          mediaData={ mediaData }
                          scale={ scale }
                          previousScale={ previousScale }
                          disableZoom={ disableZoom }
                          isZoomEnabled={ isQuickAdd ? false : isZoomEnabled }
                        />
                    );
                } }
            </TransformWrapper>
        );
    }

    renderSlide(media: MediaGalleryEntry, index: number): ReactElement {
        const { activeImage } = this.state;
        const { media_type } = media;

        if (activeImage === undefined) {
            return null;
        }

        switch (media_type) {
        case MediaType.IMAGE:
            return this.renderImage(media, index);
        case MediaType.VIDEO:
            return this.renderVideo(media, index);
        case MediaType.PLACEHOLDER:
            return this.renderPlaceholder(index);
        default:
            return null;
        }
    }

    // eslint-disable-next-line consistent-return
    renderDesktopGallery(): ReactElement {
        const { gallery } = this.props;

        return (
            <Suspense fallback={ <div /> }>
                    { gallery.map(this.renderImage.bind(this)) }
            </Suspense>
        );
    }

    // eslint-disable-next-line consistent-return
    renderSlider(): ReactElement {
        const {
            gallery,
            isZoomEnabled,
            sliderRef,
            isMobile,
            isQuickAdd,
        } = this.props;

        const { activeImage } = this.state;

        const isMoreThanOnePhoto = gallery.length > 1;

        return (
            <div
              ref={ this.imageRef }
              block="ProductGallery container-fw"
              elem="SliderWrapper"
            >
                <meta itemProp="image" content={ this.getImageUrl() } />
                <Slider
                  sliderRef={ sliderRef }
                  showCounter={ isMobile || isQuickAdd }
                  showArrows={ !isMobile && isMoreThanOnePhoto }
                  activeImage={ activeImage }
                  onActiveImageChange={ this.onActiveImageChange }
                  isInteractionDisabled={ isQuickAdd ? false : isZoomEnabled }
                  onClick={ this.handleSliderClick }
                  isHeightTransitionDisabledOnMount
                >
                    { gallery.map(this.renderSlide.bind(this)) }
                </Slider>
            </div>
        );
    }

    handleSliderClick(): void {
        const {
            handleImageZoomPopupActiveChange,
            gallery,
            activeImage,
            isQuickAdd,
        } = this.props;

        if (isQuickAdd) {
            return;
        }

        if (activeImage === undefined) {
            return;
        }

        const { media_type } = gallery[activeImage];

        if (media_type === MediaType.VIDEO) {
            return;
        }

        handleImageZoomPopupActiveChange(true);
    }

    render(): ReactElement {
        const { isMobile, isQuickAdd } = this.props;

        if (!isMobile && !isQuickAdd) {
            return (
                <div block="ProductGallery" ref={ this.galleryRef }>
                    { this.renderDesktopGallery() }
                </div>
            );
        }

        return (
            <div block="ProductGallery" ref={ this.galleryRef }>
                { this.renderSlider() }
            </div>
        );
    }
}
export default ProductGalleryComponent;

/** @namespace Inov8/Component/ProductGallery/Component/renderSlider */
export function renderSlider() {
    throw new Error('Function not implemented.');
}
