/**
 * Klarna Checkout compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { ProductType } from 'Component/Product/Product.config';

/** @namespace Scandiweb/KlarnaCheckoutSpwa/Util/GetPrice/getProductPrice */
export const getProductPrice = (props) => {
    const {
        productPrice: {
            price: {
                finalPrice: {
                    value: currentPrice = 0,
                } = {},
            } = {},
        } = {},
        quantity,
        product: {
            type_id,
        } = {},
    } = props;

    if (type_id === ProductType.GROUPED && typeof quantity === 'object') {
        const groupedQuantity = Object.values(quantity).reduce((acc, curr) => acc + curr, 0);

        return parseInt(currentPrice, 10) * parseInt(groupedQuantity, 10);
    }

    return parseInt(currentPrice, 10) * parseInt(quantity, 10);
};

/** @namespace Scandiweb/KlarnaCheckoutSpwa/Util/GetPrice/getCartTotalPrice */
export const getCartTotalPrice = (props) => {
    const {
        totals: {
            prices: {
                grand_total: {
                    value: totalPrice,
                } = {},
            } = {},
        } = {},
    } = props;

    return totalPrice;
};
