/**
 * Amasty Special Promotions Pro compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PureComponent } from 'react';

import Link from 'Component/Link';

import {
    AFTER_BANNER,
    PRODUCT_LABEL,
    TOP_BANNER,
} from '../../config/Banner.config';
import {
    altText,
    hoverText,
    image,
    link,
    position,
    renderEmpty,
} from '../../types/Banner.type';

import './Banner.style';

/** @namespace Scandiweb/AmastySpecialPromotionsPro/Component/Banner/Component */
export class BannerComponent extends PureComponent {
    static propTypes = {
        image: image.isRequired,
        link,
        altText,
        hoverText,
        position,
        renderEmpty,
    };

    static defaultProps = {
        link: '',
        altText: '',
        hoverText: '',
        position: TOP_BANNER,
        renderEmpty: false,
    };

    classMap = {
        [AFTER_BANNER]: 'AfterBanner',
        [PRODUCT_LABEL]: 'Label',
        [TOP_BANNER]: 'TopBanner',
    };

    renderImage() {
        const {
            image,
            altText,
            hoverText,
        } = this.props;

        return (
            <img
              src={ image }
              title={ hoverText }
              alt={ altText }
            />
        );
    }

    renderWithLink() {
        const { link: url, position } = this.props;

        return (
            <Link block="AmastyBanner" elem={ this.classMap[position] } to={ url }>
                { this.renderImage() }
            </Link>
        );
    }

    renderEmpty() {
        const { position } = this.props;

        return (
            <div block="AmastyBanner" elem={ this.classMap[position] } mods={ { isEmpty: true } } />
        );
    }

    render() {
        const {
            link: url,
            renderEmpty,
        } = this.props;

        if (renderEmpty) {
            return this.renderEmpty();
        }

        if (url) {
            return this.renderWithLink();
        }

        return this.renderImage();
    }
}

export default BannerComponent;
