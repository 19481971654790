import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductLinksContainer as SourceProductLinksContainer,
} from 'SourceComponent/ProductLinks/ProductLinks.container';
import LinkedProductsReducer from 'SourceStore/LinkedProducts/LinkedProducts.reducer';
import { withReducers } from 'Util/DynamicReducer';

/** @namespace Inov8/Component/ProductLinks/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Inov8/Component/ProductLinks/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

/** @namespace Inov8/Component/ProductLinks/Container */
export class ProductLinksContainer extends SourceProductLinksContainer {
    // TODO implement logic
}

export default withReducers({
    LinkedProductsReducer,
})(connect(mapStateToProps, mapDispatchToProps)(ProductLinksContainer));
