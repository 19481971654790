/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable fp/no-let */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */

import Link from '@scandipwa/scandipwa/src/component/Link';
import RenderWhenVisible from '@scandipwa/scandipwa/src/component/RenderWhenVisible';
import { Suspense } from 'react';

import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import ContentWrapper from 'SourceComponent/ContentWrapper';
import Html from 'SourceComponent/Html';
import ProductActions from 'SourceComponent/ProductActions';
import ProductGallery from 'SourceComponent/ProductGallery';
import ProductLinks from 'SourceComponent/ProductLinks';
import NoMatchHandler from 'SourceRoute/NoMatchHandler';
import {
    ProductPageComponent as SourceProductPageComponent,
} from 'SourceRoute/ProductPage/ProductPage.component';
import { LinkedProductType } from 'SourceStore/LinkedProducts/LinkedProducts.type';
import { ReactElement } from 'SourceType/Common.type';
import { isSignedIn } from 'SourceUtil/Auth/IsSignedIn';
import { lowPriorityLazy } from 'SourceUtil/Request/LowPriorityRender';
import { getStoreState } from 'SourceUtil/Store';

import '../../style/global.scss';

export const FeaturesCarousel = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "pdp-misc" */
    'Component/FeaturesCarousel/FeaturesCarousel.container'
));

export const PDPExplainerVideo = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "pdp-misc" */
    'Component/PDPExplainerVideo/PDPExplainerVideo.container'
));

export const PDPCampaignVideo = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "pdp-misc" */
    'Component/PDPCampaignVideo/PDPCampaignVideo.container'
));

export const ProductIcons = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "pdp-misc" */
    'Component/ProductIcons/ProductIcons.container'
));

export const ProductAwards = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "pdp-misc" */
    'Component/ProductAwards/ProductAwards.container'
));

export const PDPCrossSellsContainer = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "pdp-misc" */
    'Component/PDPCrossSells/PDPCrossSells.container'
));

export const LandingPageLinkPDP = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "pdp-misc" */
    'Component/LandingPageLinkPDP'
));

export const SizeAndFit = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "pdp-misc" */
    'Component/SizeAndFit'
));

export const TrustPilotProductReviewComponent = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "pdp-misc" */
    'Component/TrustPilotProductReview/TrustPilotProductReview.component'
));

export const PDPDropdownContainer = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "pdp-misc" */
    'Component/PDPDropdown/PDPDropdown.container'
));

/** @namespace Inov8/Route/ProductPage/Component */
export class ProductPageComponent extends SourceProductPageComponent {
    // TODO implement logic
    renderProductName(): ReactElement {
        const { dataSource: { name } } = this.props;

        return (
            <h1 block="ProductPage" elem="Title" itemProp="name">
                <TextPlaceholder content={ name } length={ TextPlaceHolderLength.MEDIUM } />
            </h1>
        );
    }

    featuresArray = ['Weighs Only 210G', 'Waterproof', 'Breathable', 'Award Winning', 'Increased Protection', 'Cardio Shoe'];

    renderAwards(): ReactElement {
        const {dataSource} = this.props;

        return (
            <Suspense fallback={ null }>
                <ProductAwards dataSource={ dataSource } />
            </Suspense>
        );
    }

    renderAdditionalSections(): ReactElement {
        const {
            areDetailsLoaded,
        } = this.props;

        return (
            <>
                <Suspense fallback={ null }>
                    <ProductLinks
                      linkType={ LinkedProductType.RELATED }
                      title={ __('STILL NOT SURE? WHAT ABOUT THESE...') }
                      areDetailsLoaded={ areDetailsLoaded }

                    />
                </Suspense>
            </>
        );
    }

    renderFeatures(): ReactElement {
        const {dataSource} = this.props;

        return (
            <RenderWhenVisible>
            <div block="FeaturesArea">
                <Suspense fallback={ null }>
                    <ProductIcons dataSource={ dataSource } />
                </Suspense>
            </div>
            </RenderWhenVisible>
        );
    }

    renderLandingPageBlock(): ReactElement {
        const {dataSource} = this.props;

        return (
            <RenderWhenVisible>
            <Suspense fallback={ null }>
                <div block="LP-Link"><LandingPageLinkPDP dataSource={ dataSource } /></div>
            </Suspense>
            </RenderWhenVisible>
        );
    }

    renderFeaturesDropdowns(): ReactElement {
        const { dataSource } = this.props;

        return (
            <RenderWhenVisible>
                <Suspense fallback={ null }>
                    <PDPDropdownContainer
                      dataSource={ dataSource }
                    />
                </Suspense>
            </RenderWhenVisible>
        );
    }

    renderProductGallery(): ReactElement {
        const {
            areDetailsLoaded,
            activeProduct,
            useEmptyGallerySwitcher,
            isVariant,
            getParameters,
        } = this.props;

        return (
            <ProductGallery
              product={ activeProduct }
              areDetailsLoaded={ areDetailsLoaded }
              isWithEmptySwitcher={ useEmptyGallerySwitcher }
              showLoader={ isVariant }
              parameters={ getParameters ? getParameters() : {} }
            />
        );
    }

    renderFeaturesCarousel(): ReactElement {
        const {dataSource} = this.props;

        return (
            <Suspense fallback={ null }>
                <FeaturesCarousel dataSource={ dataSource } />
            </Suspense>
        );
    }

    renderPDPCampaignVideo(): ReactElement{
        const {dataSource} = this.props;

        return (
            <Suspense fallback={ null }>
                <PDPCampaignVideo dataSource={ dataSource } />
            </Suspense>
        );
    }

    renderPDPExplainerVideo(): ReactElement{
        const {dataSource} = this.props;

        return (
            <Suspense fallback={ null }>
                <PDPExplainerVideo dataSource={ dataSource } />
            </Suspense>
        );
    }

    renderTrustPilotReviews(): ReactElement {
        const {activeProduct} = this.props;

        if (!activeProduct) {
            return null;
        }

        return (
            <Suspense fallback={ null }>
                <TrustPilotProductReviewComponent product={ activeProduct } />
            </Suspense>
        );
    }

    renderLongDescription() {
        const { dataSource } = this.props;

        if (!dataSource?.description) {
            return null;
        }

        const { html } = dataSource.description;

        if (!html) {
            return null;
        }

        return (
            <Html content={ html } />
        );
    }

    renderProductActions() {
        const {
            getLink,
            dataSource,
            areDetailsLoaded,
            setActiveProduct,
            parameters,
            setParameters,
        } = this.props;

        return (
            <>
            { dataSource && dataSource.sku === 'ECOM-SUB' ? (
                <div block="ProductPage" elem="StickyContainer">
                    <div block="ProductPage" elem="ProductActions">
                        <h1 block="ProductPage" elem="Title" itemProp="name">Become An Inov8 Subscriber</h1>
                        <p>Receive 20% off all products, including sale items, benefit from free delivery on any order and gain priority access to new footwear and clothing launches weeks before anyone else.</p>
                        { isSignedIn() ? (
                            <Suspense fallback={ this.renderProductActionsPlaceholder() }>
                            <ProductActions
                              getLink={ getLink }
                              product={ dataSource }
                              parameters={ parameters }
                              areDetailsLoaded={ areDetailsLoaded }
                              setActiveProduct={ setActiveProduct }
                            />
                            </Suspense>
                        ) : (
                            <p>To purchase this product please log in.</p>
                        ) }
                    </div>
                </div>
            ) : (
                <div block="ProductPage" elem="ProductActions">
                    { this.renderProductDesktopMainData() }
                    <Suspense fallback={ this.renderProductActionsPlaceholder() }>
                        <ProductActions
                          getLink={ getLink }
                          product={ dataSource }
                          parameters={ parameters }
                          areDetailsLoaded={ areDetailsLoaded }
                          setActiveProduct={ setActiveProduct }
                          setParameters={ setParameters }
                          isPdp
                        />
                    </Suspense>
                    { dataSource.sku !== 'ECOM-SUB' && this.renderSizeAndFit() }
                </div>
            ) }
            </>

        );
    }

    renderSizeAndFit() {
        const {
            dataSource,
        } = this.props;

        return (
            <Suspense fallback={ null }>
                <SizeAndFit dataSource={ dataSource } />
            </Suspense>
        );
    }

    renderCrossSells() {
        const {
            dataSource,
        } = this.props;

        return (
            <Suspense fallback={ null }>
                <PDPCrossSellsContainer dataSource={ dataSource } />
            </Suspense>
        );
    }

    selectedLabel() {
        const {
            activeProduct,
            parameters,
        } = this.props;

        // Example usage of activeProduct
        let selectedOptionLabel = '';

        if (activeProduct && activeProduct.attributes && activeProduct.attributes.colours && activeProduct.attributes.colours.attribute_options) {
            const attributeOptions = activeProduct.attributes.colours.attribute_options;
            const selectedOption = parameters?.colours?.toString();
            selectedOptionLabel = selectedOption ? attributeOptions[selectedOption]?.label : '';
        }

        return selectedOptionLabel;
    }

    renderProductPageContentDesktop(): ReactElement {
        return (
            <>
                <div block="top-container">
                    <div block="left">
                        { this.renderProductGallery() }
                        <ContentWrapper>
                            { this.renderPDPExplainerVideo() }
                            { this.renderAwards() }
                            { this.renderFeatures() }
                            { this.renderFeaturesDropdowns() }
                            { this.renderLandingPageBlock() }
                        </ContentWrapper>
                    </div>

                    <div
                      block="right"
                    >
                        { this.renderProductActions() }
                    </div>

                </div>

                <div block="bottom-container">
                    { this.renderFeaturesCarousel() }
                    { this.renderPDPCampaignVideo() }
                    { this.renderTrustPilotReviews() }
                    { this.renderCrossSells() }
                </div>
            </>
        );
    }

    renderProductPageContentMobile(): ReactElement {
        return (
            <div>
                { this.renderProductGallery() }
                { this.renderProductActions() }
                { this.renderPDPExplainerVideo() }
                { this.renderAwards() }
                { this.renderFeatures() }
                { this.renderFeaturesDropdowns() }
                { this.renderLandingPageBlock() }
                { this.renderFeaturesCarousel() }
                { this.renderPDPCampaignVideo() }
                { this.renderTrustPilotReviews() }
                { this.renderCrossSells() }
            </div>
        );
    }

    renderSubscriptionProductPageContent(): ReactElement {
        const { ConfigReducer: { secure_base_media_url, base_link_url } = {} } = getStoreState();

        return (
            <div block="SubscriptionProductPage" elem="InnerContainer">
                <div block="SubscriptionProductPage" elem="TopContainer">
                    <img
                      src={ `${secure_base_media_url}wysiwyg/subscription/subscription-d.jpg` }
                      height="680"
                      alt="Roclite PRO G 400 and Roclite G 345 GTX V2 being showcased"
                      block="hidden"
                      elem="mobile"
                    />
                    <img
                      src={ `${secure_base_media_url}wysiwyg/subscription/subscription-m.jpg` }
                      width="430"
                      height="600"
                      alt="Roclite PRO G 400 and Roclite G 345 GTX V2 being showcased"
                      block="mobile"
                      elem="only"
                    />
                </div>
                <div block="SubscriptionProductPage" elem="BenefitsContainer">
                    <div block="SubscriptionProductPage" elem="Benefits">
                        <h2>Why Become A Subscriber?</h2>
                        <p>
                        Platinum Members can enjoy a whole range of perks and exclusives – all for a monthly £50 payment that
                        becomes store credit. When you join, you also receive a free pair of
                        { ' ' }
                        <Link
                          to={ `${base_link_url}sunglasses-classic` }
                        >
                            Classic Sunglasses
                        </Link>
                        , worth £70.
                        </p>

                        <p>
                            Your £50 monthly payment becomes store credit to use how you want – a great way to save up for your next
                            pair of running or gym shoes. There’s no cheaper way to buy on inov-8.com or in our stores, as you’ll
                            get 20% off all orders (even for items in sale). Your membership is also full flexible – pause or cancel
                            anytime.
                        </p>

                        <h3>Subscriber Benefits</h3>
                        <ul block="SubscriptionProductPage" elem="TickList">
                            <li>
                                <p><strong>20% off all orders, including sale items</strong></p>
                            </li>
                            <li>
                                <p><strong>Free Sunglasses worth £70.00</strong></p>
                            </li>
                            <li>
                                <p><strong>Free Delivery 365 days a year</strong></p>
                            </li>
                            <li>
                                <p><strong>Free Returns on all orders</strong></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    render(): ReactElement {
        const mobile = window.innerWidth <= 809;
        const desktop = window.innerWidth >= 810;
        const { dataSource } = this.props;

        if (dataSource.sku === 'ECOM-SUB') {
            return (
                <NoMatchHandler>
                <main
                  block="SubscriptionProductPage"
                  aria-label="Product page"
                  itemScope
                  itemType="http://schema.org/Product"
                >
                    <div block="SubscriptionProductPage" elem="Container">
                        { this.renderProductActions() }
                        { this.renderSubscriptionProductPageContent() }
                    </div>
                    <section block="SubscriptionProductPage" elem="Details">
                        <div>
                            <h3>Frequently Asked Questions</h3>
                            <div>
                                <details>
                                    <summary>
                                        How Does It Work?
                                    </summary>
                                    <p>
    When you sign up to be a Platinum Member, you`ll be asked to pay your first monthly payment of
                                        £50. This will then appear as £50 credit on your account and will activate your membership
                                        benefits
                                    </p>
                                </details>

                                <details>
                                    <summary>
                                        How Can You Check Your Balance?
                                    </summary>
                                    <p>You can check your subscription balance by logging into your account at any time.</p>
                                    <p>If you’re logged in, you will also be shown your available balance before you checkout.</p>
                                </details>

                                <details>
                                    <summary>
                                        Can You Use Your Platinum Member Benefits In An INOV8 Store?
                                    </summary>
                                    <p>
    Yes, you can use your Platinum Member benefits in any inov8 store. When paying you’ll need to
                                        provide your email address so the sales assistant can apply your discount and use your store
                                        credit.
                                    </p>
                                </details>

                                <details>
                                    <summary>
                                        Are There Any Restrictions?
                                    </summary>
                                    <p>There are three restrictions</p>
                                    <ol>
                                        <li>You can’t use your Platinum Member benefits alongside any voucher codes</li>
                                        <li>You can’t use your credit to buy gift vouchers</li>
                                        <li>
    You can only use your benefits on orders being shipped to the UK or US. UK Platinum Members
                                            can only use their benefits on UK orders. US Platinum Members can only use their benefits on
                                            US order
                                        </li>
                                    </ol>
                                </details>
                                <details>
                                    <summary>
                                        Can You Pause Or Cance Your Subscription?
                                    </summary>
                                    <p>Yes, you can pause or cancel your Platinum Membership at any time.</p>
                                    <p><b>If you pause your membership…</b></p>
                                    <p>You’ll lose your Platinum Member benefits such as 20% off everything, free delivery, etc. However, you can still spend any of your credit online or in store. You can then re-activate your membership whenever you’re ready.</p>
                                    <p><b>If you cancel your membership…</b></p>
                                    <p>You’ll lose your Platinum Member benefits and we’ll refund you any balance on your account.</p>

                                </details>
                            </div>
                        </div>

                    </section>
                </main>
                </NoMatchHandler>
            );
        }

        return (
            <>
            <NoMatchHandler>
                <main
                  block="ProductPage"
                  aria-label="Product page"
                  itemScope
                  itemType="http://schema.org/Product"
                >
                    { mobile && this.renderProductPageContentMobile() }
                    { desktop && this.renderProductPageContentDesktop() }
                    { this.renderAdditionalSections() }
                </main>
            </NoMatchHandler>
            </>
        );
    }
}

export default ProductPageComponent;
